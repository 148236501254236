import supabase from "../../utils/supabase";

import React, { useState, useEffect } from "react";

export default function ProxyHistory({ localProxyCount }) {
  const [proxied_requests, setProxied_requests] = useState([]);
  const refresh_proxied_requests = async () => {
    let { data: proxied_requests } = await supabase
      .from("proxied_requests")
      .select("*")
      .order("created_at", { ascending: false })

      .limit(20);

    setProxied_requests(proxied_requests || []);
  };

  useEffect(() => {
    refresh_proxied_requests();
  }, [localProxyCount]);

  return (
    <div className="bg-white overflow-hidden shadow mx-auto max-w-7xl mt-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 ">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    URL
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    UTC Timestamp
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {proxied_requests.map((request, personIdx) => (
                  <tr
                    key={request.id}
                    className={personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {request.url}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {request.created_at}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {request.response_data.statusCode}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
