import React, { useEffect, useState } from "react";
import supabase from "../../utils/supabase";
export default function Metrics() {
  const [count, setCount] = useState(true);

  async function getMetrics() {
    const { data, count } = await supabase
      .from("proxied_requests")
      .select("*", { count: "exact" });
    setCount(count);
    console.log(data);
    console.log(count);
  }

  useEffect(() => {
    getMetrics();
  });

  return (
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">{count} Metrics here</div>
  );
}
