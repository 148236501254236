import React, { useEffect, useState } from "react";
import "prismjs/themes/prism-okaidia.css";

export default function Home() {
  return (
    <iframe
      style={{
        width: "100%",
        padding: 0,
        border: 0,
        minHeight: "500px",
      }}
      src={process.env.REACT_APP_QUARK_PLANS_URL}
    />
  );
}
