import React, { useState } from "react";
import ProxyForm from "./proxy/Proxy_form";
import ProxyHistory from "./proxy/proxy_history";

export default function Dashboard() {
  const [localProxyCount, setLocalProxyCount] = useState(0);

  return (
    <main>
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="bg-white overflow-hidden shadow">
          <div className="px-4 py-5 sm:p-6">
            <ProxyForm
              localProxyCount={localProxyCount}
              setLocalProxyCount={setLocalProxyCount}
            ></ProxyForm>
          </div>
        </div>

        <ProxyHistory
          setLocalProxyCount
          localProxyCount={localProxyCount}
        ></ProxyHistory>
      </div>
    </main>
  );
}
