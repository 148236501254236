/**
 * Global Config
 */

const config = {};

// Domains
config.domains = {};

config.domains.api =
  process.env.REACT_APP_API_URL || "https://dev-api.safe-proxy.tech";

export default config;
