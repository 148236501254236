import ApiKeys from "./api_keys";
export default function UserSettings({ session }) {
  return (
    <main>
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="px-4 py-5 sm:p-6">
          <ApiKeys></ApiKeys>
        </div>
      </div>
    </main>
  );
}
