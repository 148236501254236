import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState, useEffect } from "react";
import supabase from "../../utils/supabase";
import { generateApiKeyRequest } from "../../utils/api";

dayjs.extend(relativeTime);
export default function ApiKeys({ session }) {
  const [apiKeys, setApiKeys] = useState([]);
  async function updatekeys() {
    let { data: api_keys } = await supabase
      .from("api_keys")
      .select("*")
      .order("created_at", { ascending: true })
      .limit(20);
    setApiKeys(api_keys);
  }

  useEffect(() => {
    updatekeys();
  }, []);

  async function generateApiKey() {
    await generateApiKeyRequest();
    updatekeys();
  }

  async function enableApiKey(key) {
    await supabase
      .from("api_keys")
      .update({ disabled: false })
      .match({ id: key.id });
    updatekeys();
  }

  async function disableApiKey(key) {
    await supabase
      .from("api_keys")
      .update({ disabled: true })
      .match({ id: key.id });
    updatekeys();
  }

  return (
    <div>
      <h1 class="text-lg leading-6 font-medium text-gray-900">API Keys</h1>
      <div className="bg-white overflow-hidden shadow  my-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      api_key
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Used
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.map((apiKey, index) => (
                    <tr
                      key={apiKey.id}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      {apiKey.disabled === true ? (
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 line-through">
                          {apiKey.api_key}
                        </td>
                      ) : (
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {apiKey.api_key}
                        </td>
                      )}

                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {apiKey.created_at}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {apiKey.last_used
                          ? dayjs(apiKey.last_used).fromNow()
                          : ""}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {apiKey.disabled === false ? (
                          <button
                            href="#"
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => disableApiKey(apiKey)}
                          >
                            Disable
                          </button>
                        ) : (
                          <button
                            href="#"
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => enableApiKey(apiKey)}
                          >
                            Enable
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        onClick={() => generateApiKey()}
      >
        Generage New Key
      </button>
    </div>
  );
}
