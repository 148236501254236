/**
 * Utils: Back-end
 */

import config from "../config";
import supabase from "./supabase";

/**
 * Register a new user
 */
export const userRegister = async (email, password) => {
  console.log("register user");
  return await requestApi("/users/register", "POST", { email, password });
};

/**
 * Login a new user
 */
export const userLogin = async (email, password) => {
  return await requestApi("/users/login", "POST", { email, password });
};

/**
 * userGet
 */
export const userGet = async (token) => {
  return await requestApi("/user", "POST", null, {
    Authorization: `Bearer ${token}`,
  });
};

export const generateApiKeyRequest = async () => {
  return await requestApi(`/account/api_key/generate`, "POST", null, {
    Authorization: `Bearer ${supabase.auth.currentSession.access_token}`,
  });
};

const getApiKey = async (attempt = 0) => {
  if (attempt > 2) {
    throw new Error("unable to get/generate api key");
  }
  const { data: k } = await supabase
    .from("api_keys")
    .select("*")
    .eq("disabled", false)
    .limit(1);
  if (k.length === 0 || k[0].disabled === true) {
    await generateApiKeyRequest();
    return await getApiKey(attempt++);
  }
  return k[0];
};

export const userFileViaProxy = async (token, data) => {
  const key = await getApiKey();
  const url = `/proxy?url=${encodeURIComponent(data.url)}&apiKey=${
    key.api_key
  }`;
  return await requestApi(
    url,
    "get",
    null,
    {
      Authorization: `Bearer ${token}`,
    },
    { download: true }
  );
};

/**
 * API request to call the backend
 */
export const requestApi = async (
  path = "",
  method = "GET",
  data = null,
  headers = {},
  opts = { json: true }
) => {
  // Check if API URL has been set
  if (!config?.domains?.api) {
    throw new Error(
      `Error: Missing API Domain – Please add the API domain from your serverless Express.js back-end to this front-end application.  You can do this in the "site" folder, in the "./config.js" file.  Instructions are listed there and in the documentation.`
    );
  }

  // Prepare URL
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  const url = `${config.domains.api}${path}`;

  // Set headers
  headers = Object.assign({ "Content-Type": "application/json" }, headers);

  // Default options are marked with *
  try {
    const response = await fetch(url, {
      method: method.toUpperCase(),
      mode: "cors",
      cache: "no-cache",
      headers,
      body: data ? JSON.stringify(data) : null,
    });

    if (response.status < 200 || response.status >= 300) {
      const error = await response.json();
      throw new Error(error.error);
    }
    if (opts.download) {
      const blob = await response.blob();
      const file = window.URL.createObjectURL(blob);
      return window.location.assign(file);
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};
