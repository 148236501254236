import React, { useState } from "react";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";

export default function WarningBanner(props) {
  const [visable, setVisable] = useState(true);
  if (!props.error) {
    return null;
  }

  return (
    <div>
      {visable && (
        <div className="bg-red-200">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 bg-red-200">
                  <SpeakerphoneIcon
                    className="h-6 w-6 text-black"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium truncate">
                  <span className="hidden md:inline">
                    {props.error.message}
                  </span>
                </p>
              </div>

              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="-mr-1 flex p-2 focus:outline-none hover:ring-white sm:-mr-2"
                  onClick={(_e) => {
                    setVisable(false);
                  }}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 " aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
