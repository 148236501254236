import React from "react";
import supabase from "../../utils/supabase";
import { useHistory } from "react-router";

export default function Logout({ setSession }) {
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("sign out");
    const session = await supabase.auth.session();
    console.log(session);
    const { error } = await supabase.auth.signOut({});
    console.log(error);
    history.push("/");
  };
  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <button
        type="submit"
        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Sign out
      </button>
    </form>
  );
}
