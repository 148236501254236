import styles from "../style/style";
import React, { useState } from "react";
import { userFileViaProxy } from "../../utils";
import supabase from "../../utils/supabase";

const ProtectedButton = ({ localProxyCount, setLocalProxyCount }) => {
  const [url, setUrl] = useState("");
  const [validUrl, setValidUrl] = useState(true);
  const [processing, setProcessing] = useState(false);

  const validateUrl = () => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const validUrl = validateUrl();
    setValidUrl(validUrl);
    if (!validUrl) {
      return;
    }

    setProcessing(true);
    await userFileViaProxy(supabase.auth.currentSession.access_token, { url });

    const newCount = Math.random();
    //hack to let server update rows before querying them
    setTimeout(() => {
      setLocalProxyCount(newCount);
    }, 100);
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label
        htmlFor="url"
        className="block text-lg font-medium text-gray-700 mb-2"
      >
        Test proxy
      </label>
      <div className="mt-1">
        <input
          type="text"
          name="url"
          id="url"
          className={
            "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 " +
            (validUrl ? " " : "border-red-600")
          }
          placeholder="http://example.com/some-img"
          aria-describedby="url-description"
          onChange={(e) => setUrl(e.target.value)}
          value={url}
        />
      </div>

      <div class="pt-5 ">
        {processing ? (
          <input
            className={"bg-gray-200 " + styles.buttonStyle}
            disabled="true"
            type="submit"
            value="processing"
          />
        ) : (
          <input
            className={styles.buttonStyle}
            type="submit"
            value="Download"
          />
        )}
      </div>
    </form>
  );
};

export default ProtectedButton;
