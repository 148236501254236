const styles = {
  buttonStyleMd:
    "shadow-lg inline-flex items-center px-7 py-3 border-2 border-gray-900 text-base font-medium text-gray-900 bg-white focus:outline-none cursor-pointer active:shadow-md text-1xl hover:bg-gray-100",
  buttonStyleLg:
    "shadow-lg inline-flex items-center px-7 py-3 border-2 border-gray-900 text-base font-medium text-gray-900 bg-white focus:outline-none cursor-pointer active:shadow-md text-3xl hover:bg-gray-100",
  buttonStyle:
    "shadow-lg inline-flex items-center px-6 py-1 border-2 border-gray-900 text-base font-medium text-gray-900 bg-white focus:outline-none cursor-pointer active:shadow-md hover:bg-gray-100",
};

export default styles;
