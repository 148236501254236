import React, { useEffect } from "react";
import styles from "../style/style";
import Prism from "prismjs";
import "prismjs/themes/prism-okaidia.css";
import Footer from "../../partials/footer";

import {
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Quick setup",
    description:
      "Setup in seconds using your favourate languge and HTTP library",
    icon: CloudUploadIcon,
  },
  {
    name: "SSL Certificates",
    description: "Validate all HTTPS Requests",
    icon: LockClosedIcon,
  },
  {
    name: "Inbuilt Retry Logic",
    description:
      "Safeproxy will retry urls by default, giving your users a better experience",
    icon: RefreshIcon,
  },
  {
    name: "Advanced Security",
    description: "Known malicious hosts are automatically blocked",
    icon: ShieldCheckIcon,
  },
  {
    name: "GET or POST requests",
    description: "Protect POST websocket requests to clients",
    icon: CogIcon,
  },
  {
    name: "Clear logs",
    description: "Clear logs of all requests help solve user frustraitions.",
    icon: ServerIcon,
  },
];

export default function Home() {
  const [openTab, setOpenTab] = React.useState(1);
  const [tabOneClass, setTabOneClass] = React.useState("block");
  const [tabTwoClass, setTabTwoClass] = React.useState("block");

  Prism.hooks.add("before-sanity-check", function (env) {
    env.code = env.element.innerText;
  });

  function flipTabs(openTab) {
    setOpenTab(openTab);
    if (openTab === 1) {
      setTabOneClass("block");
      setTabTwoClass("hidden");
    } else {
      setTabOneClass("hidden");
      setTabTwoClass("block");
    }
  }

  useEffect(() => {
    Prism.highlightAll();
    setTabTwoClass("hidden");
  }, []);

  const color = "blue";

  return (
    <main className="">
      <div className="bg-gradient-to-r from-pink-700  to-yellow-600 mt-5 p-20">
        <div className="text-center mx-auto max-w-7xl px-4 ">
          <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
            <span className="block xl:inline">SSRF</span>{" "}
            <span className="block text-white xl:inline">Protection</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Protect your application from Server-Side Request Forgery attacks.
            Safe Proxy lets your application to safley fetch a remote URL.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="shadow text-3xl">
              <a href="/register" className={styles.buttonStyleLg}>
                Get started
              </a>
            </div>
          </div>
        </div>
        {/* Feature section with grid */}
      </div>

      <div className="relative bg-white py-16 sm:py-24 lg:py-20">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-gray-900 uppercase">
            Never trust user input
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Request unsafe URL's from the safety of outside your network
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500"></p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50  px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-600">
                          <feature.icon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue-700" id="quick-start">
        <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Quick Start</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-indigo-200">
            Use our library or use your favourate HTTP libray
          </p>
          <div className="flex flex-wrap">
            <div className="w-full">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 flex-row"
                role="tablist"
              >
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center rounded">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                      (openTab === 1
                        ? "text-" + color + "-600 bg-white"
                        : "text-white bg-" + color + "-600")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      flipTabs(1);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    <i className="fas fa-space-shuttle text-base mr-1"></i>
                    Node.js
                  </a>
                </li>
                <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                  <a
                    className={
                      "text-xs font-bold uppercase px-5 py-3 block leading-normal " +
                      (openTab === 2
                        ? "text-" + color + "-600 bg-white"
                        : "text-white bg-" + color + "-600")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      flipTabs(2);
                    }}
                    data-toggle="tab"
                    href="#link2"
                    role="tablist"
                  >
                    <i className="fas fa-cog text-base mr-1"></i> Other
                  </a>
                </li>
              </ul>
              <div className="tab-content tab-space">
                <div className={tabOneClass}>
                  <pre>
                    <code className="language-js">
                      <div>npm install node-safe-proxy --save</div>
                      <div> </div>
                      <div>
                        const SafeProxy =
                        require("node-safe-proxy")(&#123;apiKey: apiKey&#125;)
                      </div>
                      <div>
                        const response = await
                        SafeProxy.get("https://unsafe.example.com")
                      </div>
                    </code>
                  </pre>
                </div>
                <div className={tabTwoClass}>
                  <pre>
                    <code className="language-js">
                      <div>// Use any standard http client</div>
                      <div></div>
                      <div>const userUrl = "https://unsafe.example.com"</div>
                      <div> </div>
                      <div>const url =</div>
                      <div>&nbsp;"https://api.safe-proxy.tech/proxy" +</div>
                      <div>&nbsp;`?apiKey=$&#123;apiKey&#125;` +</div>
                      <div>
                        &nbsp;`&url=$&#123;encodeURIComponent(userUrl)&#125;`;
                      </div>
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
          <a href="register" className={styles.buttonStyleMd + " mt-3"}>
            Sign up now
          </a>
        </div>
      </div>

      <div className="bg-white-700">
        <div className="max-w-3xl mx-auto  py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-grey-800 sm:text-4xl text-center">
            <span className="block">What is Server-Side Request Forgery?</span>
          </h2>
          <p className="m-4 text-lg leading-6 text-grey-900 uppercase text-center">
            SSRF is a OWASP top 10 web application security risk.
          </p>
          <div className="flex flex-wrap mt-8">
            <div className="w-full bg-gray-50 p-5 ">
              <span class="inline-flex text-centre float-left justify-left p-3 bg-blue-600 text-white text-3xl font-extrabold sm:text-4xl rounded px-4">
                1
              </span>
              <div class="mx-16 text-lg">
                Users enters a URL for an image, webhook or any HTTP resource
                your application uses
              </div>
            </div>

            <div className="w-full bg-gray-50 p-5 my-12">
              <span class="inline-flex float-right justify-right p-3 bg-blue-600 text-white text-3xl font-extrabold sm:text-4xl rounded">
                2
              </span>
              <div class="mr-10 text-lg text-right float-right w-min">
                Malicious user enters an internal private URL
                <pre>
                  <code className="language-uri">
                    <div>internal.hostname.example/unsecured</div>
                  </code>
                </pre>
              </div>
            </div>
            <div className="w-full bg-gray-50 p-5 ">
              <span class="inline-flex float-left justify-left p-3 bg-blue-600 text-white text-3xl font-extrabold sm:text-4xl rounded">
                3
              </span>
              <div class="mx-16 text-lg pt-4">
                Private content is potentially exposed to malicious user
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </main>
  );
}
