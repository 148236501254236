import { createClient } from "@supabase/supabase-js";

if (!process.env.REACT_APP_SUPABASE_URL) {
  console.log("REACT_APP_SUPABASE_URL empty");
}

if (!process.env.REACT_APP_SUPABASE_PUBLIC_KEY) {
  console.log("REACT_APP_SUPABASE_PUBLIC_KEY empty");
}

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_PUBLIC_KEY
);

export default supabase;
