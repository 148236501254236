import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import Header from "./partials/header";
import Dashboard from "./pages/Dashboard";

import Register from "./pages/user/register";
import Logout from "./pages/user/logout";
import Login from "./pages/user/login";
import Profile from "./pages/user/profile";
import Home from "./pages/marketing/home";
import Plans from "./pages/marketing/plans";
import supabase from "./utils/supabase";
import ProxyHistory from "./pages/proxy/proxy_history";
import ProxyMetrics from "./pages/proxy/proxy_metrics";
import UserSettings from "./pages/user/settings";
export default function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    setSession(supabase.auth.session());

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Header session={session} useLocation={useLocation} />

        <Switch>
          <Route path="/register">
            <Register />
          </Route>

          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/plans">
            <Plans />
          </Route>
          <Route path="/user/profile">
            <Profile session={session} something={true} />
          </Route>
          <Route path="/user/settings">
            <UserSettings session={session} />
          </Route>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/metrics" component={ProxyMetrics} />
          <PrivateRoute exact path="/logs" component={ProxyHistory} />

          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

/**
 * A component to protect routes.
 * Shows Auth page if the user is not authenticated
 */
const PrivateRoute = ({ component, ...options }) => {
  const session = supabase.auth.session();
  if (!session) return <div>please Log in!</div>;

  // const finalComponent = session ? Dashboard : Home;
  return <Route {...options} component={component} />;
};
